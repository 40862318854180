import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  CardContent,
  CircularProgress,
  Fab,
  Grid,
  makeStyles,
  Tab,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { OrganizationTier } from "@prisma/client";
import { isBefore } from "date-fns";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../src/api/types";
import { IEvent } from "../src/api/types/event";
import { ErrorsBox } from "../src/components/ErrorsBox";
import HeaderBox from "../src/components/HeaderBox";
import Link from "../src/components/Link";
import SEO, { ISEOProps } from "../src/components/SEO";
import ConferenceDetailsDialog from "../src/components/dialogs/ConferenceDetailsDialog";
import DeleteEventDialog from "../src/components/dialogs/DeleteEventDialog";
import DuplicateEventDialog from "../src/components/dialogs/DuplicateEventDialog";
import PublicShareDialog from "../src/components/dialogs/PublicShareDialog";
import EventBadge from "../src/components/event/EventBadge";
import EventListItem from "../src/components/event/EventListItem";
import Page from "../src/components/layouts/Page";
import { FlexSpacer, HorizontalSpacer } from "../src/components/utils/spacers";
import useDialog from "../src/hooks/useDialog";
import { useErrors } from "../src/hooks/useErrors";
import { useIsLoading } from "../src/hooks/useIsLoading";
import { eventsActions } from "../src/store/modules/events";
import { ApiEventKeys } from "../src/store/modules/events/keys";
import { selectAllEvents } from "../src/store/modules/events/selectors";
import { selectUser } from "../src/store/modules/user/selectors";
import withAuth from "../src/utils/withAuth";

const namespaces = [
  "home",
  "common",
  "errors",
  "event_basic",
  "event_actions",
  "delete_event_modal",
];

const seoProps: ISEOProps = {
  title: "dashboard",
};

const useStyles = makeStyles((theme) => ({
  page: {
    backgroundColor: theme.palette.grey[100],
    minHeight: "100vh",
  },
  createIconWrapper: {
    boxShadow: theme.shadows[3],
  },
  cardLink: {
    textDecoration: "none",
    underline: "none",
  },
  noEventsContent: {
    paddingTop: theme.spacing(2),
  },
  noEventsSubtitle: {
    paddingTop: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  plus: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(4),
    fontWeight: 400,
    color: theme.palette.primary.main,
    width: "50px",
    height: "50px",
  },
  eventListGrid: {
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      flexDirection: "column",
    },
  },
  cardHeader: {
    color: theme.palette.primary.main,
    textOverflow: "ellipsis",
    width: "100%",
    paddingRight: theme.spacing(3.5),
  },
  tabs: {
    // Hack to have 2px border at bottom, but inside (inset)
    boxShadow: `inset 0px -10px 0px -8px ${theme.palette.grey[200]}`,
  },
  bold: {
    fontWeight: 600,
  },
}));

export default withAuth(function Home() {
  const { t } = useTranslation(namespaces);
  const events = useSelector(selectAllEvents)?.events;
  const classes = useStyles();
  const { organization } = useSelector(selectUser);

  return (
    <Page className={classes.page} disableMargin>
      <SEO {...seoProps} />
      <HeaderBox>
        <Typography variant="h1" data-qa="header">
          {t("common:dashboard")}
        </Typography>
        <HorizontalSpacer />
        <FlexSpacer />
        {organization.tier !== OrganizationTier.disabled && events?.length ? (
          <div>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Fab
              component={Link}
              href="/event/create"
              naked
              color="primary"
              size="medium"
              className={classes.createIconWrapper}
              data-qa="create-event"
              variant="extended"
            >
              <FontAwesomeIcon icon={faPlus} size="lg" />
              <HorizontalSpacer />
              {t("home:create")}
            </Fab>
          </div>
        ) : null}
      </HeaderBox>
      <Events />
    </Page>
  );
}, seoProps);

enum EventsListTabs {
  upcoming = "upcoming",
  past = "past",
}

function Events() {
  const dispatch = useDispatch();
  const { t } = useTranslation(namespaces);
  const classes = useStyles();
  const conferenceDetailsDialog = useDialog();
  const publicShareDialog = useDialog();
  const deleteDialog = useDialog();
  const duplicateDialog = useDialog();

  const { events, results, participants } = useSelector(selectAllEvents) || {};
  const isLoading = useIsLoading(ApiEventKeys.fetchAll);
  const errors = useErrors(ApiEventKeys.fetchAll);
  const { organization } = useSelector(selectUser);
  const [selectedEvent, setSelectedEvent] = useState<IEvent>(null);
  const [tab, setTab] = useState(EventsListTabs.upcoming);

  const onTabChange = useCallback((_, tab: EventsListTabs) => {
    setTab(tab);
  }, []);

  useEffect(() => {
    dispatch(eventsActions.fetchAll());
  }, []);

  const openDeleteDialog = useCallback(
    (event: IEvent) => {
      setSelectedEvent(event);
      deleteDialog.onOpenDialog();
    },
    [deleteDialog.onOpenDialog]
  );

  const openConferenceDetailsDialog = useCallback(
    (event: IEvent) => {
      setSelectedEvent(event);
      conferenceDetailsDialog.onOpenDialog();
    },
    [conferenceDetailsDialog.onOpenDialog]
  );

  const openPublicShareDialog = useCallback(
    (event: IEvent) => {
      setSelectedEvent(event);

      publicShareDialog.onOpenDialog();
    },
    [publicShareDialog.onOpenDialog]
  );

  const openDuplicateEventDialog = useCallback(
    (event: IEvent) => {
      setSelectedEvent(event);

      duplicateDialog.onOpenDialog();
    },
    [duplicateDialog.onOpenDialog]
  );

  const onDeleteEvent = useCallback(() => {
    if (selectedEvent?.id) {
      dispatch(eventsActions.delete(selectedEvent.id));
    }
  }, [selectedEvent?.id]);

  const compare = (eventA: IEvent, eventB: IEvent) => {
    const map = {};
    map[State.ongoing] = 1;
    map[State.awaiting] = 2;

    if (map[eventA.state] < map[eventB.state]) {
      return -1;
    } else if (map[eventA.state] > map[eventB.state]) {
      return 1;
    } else {
      if (eventA.hasSentInvitation && !eventB.hasSentInvitation) {
        return -1;
      } else if (!eventA.hasSentInvitation && eventB.hasSentInvitation) {
        return 1;
      }
      return 0;
    }
  };

  if (errors) {
    return <ErrorsBox errors={errors} />;
  }

  if (isLoading || !events) {
    return <CircularProgress />;
  }

  const upcomingEvents =
    events?.filter((event) => event.state !== State.completed).sort(compare) ??
    [];

  const pastEvents =
    events
      ?.filter((event) => event.state == State.completed)
      ?.sort((a, z) =>
        isBefore(new Date(a.start), new Date(z.start)) ? 1 : -1
      ) ?? [];

  return (
    <TabContext value={tab}>
      <TabList
        variant="scrollable"
        scrollButtons="auto"
        onChange={onTabChange}
        indicatorColor="primary"
        className={classes.tabs}
      >
        <Tab
          label={t("event_detail:upcomingEvents", {
            count: upcomingEvents.length,
          })}
          value={EventsListTabs.upcoming}
          classes={{
            selected: classes.bold,
          }}
          data-qa="events-tab-upcoming"
        />
        <Tab
          label={t("event_detail:pastEvents")}
          value={EventsListTabs.past}
          classes={{
            selected: classes.bold,
          }}
          data-qa="events-tab-past"
        />
      </TabList>
      <TabPanel value={EventsListTabs.upcoming}>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="stretch"
          className={classes.eventListGrid}
          data-qa="events-panel-upcoming"
        >
          {upcomingEvents.length ? (
            upcomingEvents.map((event) => (
              <EventListItem
                key={event.id}
                event={event}
                participants={participants[event.id]}
                results={results[event.id]}
                onDeleteClick={openDeleteDialog}
                onConferenceDetailsClick={openConferenceDetailsDialog}
                onDuplicateClick={openDuplicateEventDialog}
                onPublicShareClick={openPublicShareDialog}
                data-qa={`events-event-upcoming-${event.id}`}
              />
            ))
          ) : (
            <Grid item xl={12}>
              {organization.tier !== OrganizationTier.disabled && (
                <Link
                  href="/event/create"
                  naked
                  className={classes.cardLink}
                  data-qa="events-create-link"
                >
                  <Card>
                    <CardContent className={classes.noEventsContent}>
                      <AddIcon className={classes.plus} />
                      <Typography variant="h6">
                        {t("event_basic:create")}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.noEventsSubtitle}
                      >
                        {t("event_basic:createSubtitle")}
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              )}
            </Grid>
          )}
        </Grid>
      </TabPanel>
      <TabPanel value={EventsListTabs.past}>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="stretch"
          data-qa="events-panel-past"
        >
          {pastEvents.map((event) => (
            <EventListItem
              key={event.id}
              event={event}
              participants={participants[event.id]}
              results={results[event.id]}
              isPast
              onDeleteClick={openDeleteDialog}
              onConferenceDetailsClick={openConferenceDetailsDialog}
              onDuplicateClick={openDuplicateEventDialog}
              onPublicShareClick={openPublicShareDialog}
              data-qa={`events-event-past-${event.id}`}
            />
          ))}
        </Grid>
      </TabPanel>
      {selectedEvent && (
        <>
          <ConferenceDetailsDialog
            event={selectedEvent}
            {...conferenceDetailsDialog}
          />
          <PublicShareDialog event={selectedEvent} {...publicShareDialog} />
          <DeleteEventDialog
            event={selectedEvent}
            onSubmit={onDeleteEvent}
            {...deleteDialog}
          />
          <DuplicateEventDialog event={selectedEvent} {...duplicateDialog} />
        </>
      )}
    </TabContext>
  );
}

export async function getServerSideProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, [
        ...namespaces,
        ...Page.namespaces,
        ...EventBadge.namespaces,
        ...EventListItem.namespaces,
        ...DeleteEventDialog.namespaces,
        ...ConferenceDetailsDialog.namespaces,
        ...PublicShareDialog.namespaces,
        ...DuplicateEventDialog.namespaces,
      ])),
    },
  };
}
